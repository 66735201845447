import { useState, useEffect } from 'react';
import _ from 'lodash';

import { 
  default as demension,
  popContent,
} from './const';

import grouplineImg from 'assets/groupline.svg';
import styles from './styles.module.scss';

const FORM_KEY = {
  NAME: 'name',
  COMPANY: 'company',
  EMAIL: 'email',
  MESSAGE: 'message'
}

const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [form, setForm] = useState({
    name: '',
    company: '',
    email: '',
    message: ''
  });
  const [formErr, setFormErr] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [popKey, setPopKey] = useState('');
  const [popScroll, setPopScroll] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if(popKey){
      document.body.style.height = '100VH';
      document.body.style.overflowY = 'hidden';
    }else{
      document.body.style.height = 'auto';
      document.body.style.overflowY = 'auto';
    }
    return () => {
      document.body.style.height = 'auto';
      document.body.style.overflowY = 'auto';
    };
  }, [popKey]);

  const onFormChange = (key, e) => {
    setForm(prev => {
      return {
        ...prev,
        [key]: e.target.value
      }
    });
    setFormErr(prev => {
      return {
        ...prev,
        [key]: false
      }
    });
  }

  const handleSubmit = () => {
    if (submitSuccess) {
      return;
    }
    let flag = false;
    const cpFormErr = { ...formErr };
    if (!form.name) {
      cpFormErr.name = '* Name is required';
      flag = true;
    }
    if (!form.email) {
      cpFormErr.email = '* Email is required';
      flag = true;
    }
    if (form.email && !EMAIL_PATTERN.test(form.email)) {
      cpFormErr.email = '* Please use a valid email address';
      flag = true;
    }

    if (!form.message) {
      cpFormErr.message = '* Message is required';
      flag = true;
    }
    
    setFormErr(cpFormErr);
    if (!flag) {
      setSubmitSuccess(true);

      let content = `Name:\n${form.name}\n\n`
      content += `Your email:\n${form.email}\n\n`
      content += `Company:\n${form.company}\n\n\n`
      content += `Message:\n${form.message}\n\n`
      
      // As no BE resouce, use alternative solution
      window.location.href = 'mailto:info@warpspeed.capital?subject=Hello Warpspeed!&body=' + encodeURIComponent(content);
      return;
    }
  }

  const handlePop = e => {
    const key = e.currentTarget.dataset.popkey;
    setPopKey(key);
  }

  const callDebounce = _.debounce(() => {
    setPopScroll(false);
  }, 1000);
  const handlePopScrolling = e => {
    if(!popScroll){
      setPopScroll(true);
    }
    if(popScroll){
      callDebounce();
    }
  }

  const renderPop = () => {
    return (
      <>
      <div data-id="" className={styles.modalMask} onClick={handlePop}></div>
      <div className={styles.popcontainer}>
        <div className={styles.popup}>
          <div className={styles.popcloseWrapper}>
            <div data-id="" className={styles.popclose} onClick={handlePop}>
              <span>CLOSE</span><span>X</span>
            </div>
          </div>
          <div className={styles.poptitle}>
            {
              popKey == 'cp'
                ? "Warpspeed Cookie Policy"
                : "Warpspeed Privacy Policy"
            }
          </div>
          <div className={styles.popseperator}></div>
          
          <div
            className={`${styles.popcontentWrapper} ${!popScroll && styles.scrollInvisible}`}
            onScroll={handlePopScrolling}
          >
            <div className={`${styles.popcontent}`}>
              <pre dangerouslySetInnerHTML={{__html: popContent[popKey]}}></pre>
            </div>
          </div>
        
        </div>
      </div>
      </>
    );
  }

  return (
    <div className={styles.app}>
      <section className={styles.headerVideo}>
        <div className={styles.videoWrapper}>
          <video
            autoPlay={true}
            loop
            muted={true}
            className={styles.video}
          >
            <source
              src={`${process.env.PUBLIC_URL}/video/warpspeed-hero-video.mp4`}
              type="video/mp4"
            />
          </video>

          <div className={styles.logoSection}>
            <img
              width={210}
              height={35}
              className={styles.titleLogo}
              src={`${process.env.PUBLIC_URL}/img/warpspeed-logo.svg`}
            />
          </div>

          {
            width > demension.mobile &&
              <div className={styles.mainText}>
                Growth Supercharged
                <div className={styles.subtitle}>
                  Warpspeed Capital focuses on decentralized finance, quantitative strategies and venture building to generate faster growth and high returns.
                </div>
                <a href="#talksection">
                  <button className={styles.talkButton}>
                    Let's Talk
                  </button>
                </a>
              </div>
          }
        </div>

        {
          width > demension.tablet && 
            <div className={styles.groupline}>
              <img
                width={217}
                height={300}
                className={styles.groupLineImg}
                src={grouplineImg}
              />
            </div>
        }
      </section>
      
      <section className={styles.introSection}>
        <div className={styles.textSection}>
          {
            width <= demension.mobile &&
              <div className={`${styles.leftText} ${styles.marginBottom}`}>
                <div className={`${styles.title} ${styles.large}`}>
                  Growth Supercharged
                </div>
                <p className={styles.content}>
                  Warpspeed Capital focuses on decentralized finance, quantitative strategies and venture building to generate faster growth and high returns.
                </p>
                <a href="#talksection">
                  <button className={`${styles.talkButton} ${styles.paddingTop}`}>
                    Let's Talk
                  </button>
                </a>
              </div>
          }
          <div className={styles.leftText}>
            <div className={styles.title}>Partners</div>
            <p className={styles.content}>
              Our experienced team takes a comprehensive approach to value creation through funding, tech development, operations, and legal support. With our extensive resources, partners can achieve greater scalability by focusing their energies on what they do best: <span className={styles.highlight}>innovate, build, and grow.</span>
            </p>
          </div>
          <div className={styles.rightText}>
            <div className={styles.title}>Investors</div>
            <p className={styles.content}>
              We leverage our track-record in the <span className={styles.highlight}>digital assets</span> space to connect investors to high-value projects and strategies for optimal returns. Taking a systematic approach, we provide access to early-stage projects vetted through our curation process and relationship network.
            </p>
          </div>
        </div>

        <div className={styles.bottomText}>
          {
            width > demension.mobile &&
              <div className={styles.linePart}></div>
          }
          <div className={styles.textPart}>
            <span className={`${styles.green} ${styles.highlight}`}>Our deep expertise in fintech and digital assets</span> helps companies deploy emerging technologies, exploit new markets and capitalize on fast-evolving opportunities.
          </div>
        </div>
      </section>

      <section className={styles.focusSection}>
        <div className={styles.title}>Focus</div>
        <div className={`${styles.content} ${styles.green}`}>
          <div>DeFi Farming</div>
          <div className={styles.minWidth}>Quantitative Strategies</div>
          <div>Venture Capital</div>
        </div>
      </section>

      <section className={styles.talkSection} id="talksection">
        <div className={styles.title}>Let’s Talk!</div>
        <div className={styles.subTitle}>Your growth starts here.</div>
        <div className={styles.formArea}>
          <div className={styles.inputLine}>
            <div className={styles.inputElement}>
              <label>Name</label>
              <input
                type="text"
                className={`${styles.textInput} ${formErr.name && styles.err}`}
                placeholder="James Johansson"
                onChange={e => onFormChange(FORM_KEY.NAME, e)}
              />
              <div className={styles.errMessage}>{formErr.name}</div>
            </div>
            <div className={`${styles.inputElement} ${styles.company}`}>
              <label>Company</label>
              <input
                type="text"
                className={styles.textInput}
                placeholder="Johansson Works Ltd."
                onChange={e => onFormChange(FORM_KEY.COMPANY, e)}
              />
            </div>
          </div>

          <div className={styles.singleInput}>
            <div className={styles.mailInput}>
              <label>Email</label>
              <input
                type="text"
                className={`${styles.textInput} ${formErr.email && styles.err}`}
                placeholder="james.johnathan@example.com"
                onChange={e => onFormChange(FORM_KEY.EMAIL, e)}
              />
            </div>
            <div className={styles.errMessage}>{formErr.email}</div>
          </div>

          <div className={styles.singleInput}>
            <div className={styles.mailInput}>
              <label>Message</label>
              <textarea
                type="text"
                className={`${styles.textInput} ${formErr.message && styles.err}`}
                placeholder="Enter your message here"
                onChange={e => onFormChange(FORM_KEY.MESSAGE, e)}
              />
              <div className={styles.errMessage}>{formErr.message}</div>
            </div>
          </div>

          <div className={styles.sendWrapper}>
            {
              submitSuccess && 
                <div className={styles.hint}>
                  <span className={`${styles.green} ${styles.marginRight}`}>Almost there!</span> Please confirm and send the message via your email or (if without email client installed) email the above message directly to info@warpspeed.capital
                </div>
            }
            <button className={`${styles.sendButton} ${submitSuccess && styles.disabled}`} onClick={handleSubmit}>
              Send Message
            </button>
          </div>

          <div className={styles.agreeText}>
            By clicking “Send Message” you agree to our 
            <span data-popkey="pp" className={styles.underline} onClick={handlePop}>
              &nbsp;Privacy Policy
            </span>
            &nbsp;and&nbsp;
            <span data-popkey="cp" className={styles.underline} onClick={handlePop}>
              Cookie Policy
            </span>
          </div>
        </div>
      </section>

      <section className={styles.footerImage}>
        <div className={styles.mask}></div>
      </section>

      <footer className={styles.footer}>
        <section className={styles.compnayIntro}>
          <img
            width={210}
            height={35}
            src={`${process.env.PUBLIC_URL}/img/warpspeed-logo.svg`}
          />
          <div className={styles.contentWrapper}>
            <div className={styles.title}>Growth Supercharged</div>
            <p className={styles.content}>
              Warpspeed Capital focuses on decentralized finance, quantitative strategies and venture building to generate faster growth and high returns. Our deep expertise in fintech and digital assets helps companies deploy emerging technologies, exploit new markets and capitalize on fast-evolving opportunities.
            </p>
          </div>
        </section>
        <div className={styles.seperator} />
        <section className={styles.copyright}>
          ©2022 All rights reserved. Warpspeed Capital
        </section>
      </footer>

      {
        popKey && renderPop()
      }
    </div>
  );
}

export default App;
