export default {
  tablet: 834,
  mobile: 576,
}

export const popContent = {
  cp: `
WARPSPEED COOKIE POLICY\n
Effective date: December 27, 2021\n
At Warpspeed Capital Management Limited (\"Warpspeed\"), we understand that your privacy is important to you and are committed to being transparent about the technologies we use. This cookie policy provides detailed information about how and when we use cookies (“Cookie Policy”). Please take a moment to familiarise yourself with our cookie practices as set out in this Cookie Policy.\n
<div style="font-weight: bold;">INTRODUCTION</div>
Our Interface uses cookies to distinguish you from other users of our Interface. This helps us to provide you with a good experience when you browse our Interface and also allows us to improve our Interface. 

By using our Interface and the Services, you are consenting to our use of cookies in accordance with this Cookie Policy and our Privacy Policy. If you do not agree to our use of cookies in this way, you should set your browser settings accordingly, disable the cookies that we use or not use our Interface or Services at all. If you disable the cookies that we use, this may impact your user experience while on the Interface.
  
The section below summarises the different types of cookies we use on our Interface, together with their respective purposes and provides you with the ability to manage these cookies./n
  
<div style="font-weight: bold;">INFORMATION ABOUT OUR USE OF COOKIES</div>  
A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
   
We use the following cookies:

a)	Strictly necessary cookies. These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
b)	Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
c)	Functionality cookies. These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
d)	Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website more relevant to you. 
e)	Tracking cookies. These cookies are used to track and share your referral-related activities on our websites.\n
Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
   
<div style="font-weight: bold;">CONTROLLING COOKIES</div>
Most browsers allow you to control cookies through their settings preferences. If you do not want cookies to be dropped on your device, you can adjust the setting of your browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. 
  
However, if you use your browser settings to block all cookies (including essential cookies), this is likely to impact your overall user experience such that the Services may no longer be usable by you. 
  
To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit www.allaboutcookies.org.
  
<div style="font-weight: bold;">CONTACT US</div>
If you have any other questions about our Cookie Policy, please contact us at: info@warpspeed.capital.
 `,
  pp:
  `Warpspeed Privacy Policy
  <div style="font-weight: bold;">This Privacy Policy is effective from December 27, 2021.</div>\nWelcome to the privacy policy of Warpspeed <span style="font-weight:bold;">(“Privacy Policy”)</span>.\n
When we mention “Warpspeed”, “we”, “us” or “our” in this Privacy Policy, we are referring to Warpspeed Capital Management Limited and its affiliates that are responsible for processing your personal data. Warpspeed is the controller and is responsible for this website.
  
We respect your privacy and are committed to protecting your personal data. This Privacy Policy will inform you as to how we look after your personal data when you visit our website at www.warpspeed.capital (regardless of where you visit it from) or any other websites, pages, features, or content we own or operate and/or when you use our mobile app or any Warpspeed application programming interface (collectively, the <span style="font-weight:bold;">"Interface"</span>) or the services offered by the Interface (<span style="font-weight: bold;">"Services")</span>.\n
The Privacy Policy sets forth the basic principles by which Warpspeed collects, retains, transfers, disposes and otherwise processes your personal data.\n
Terms used throughout this Privacy Policy shall have the meaning ascribed to them in the General Data Protection Regulation (EU) 2016/679 (<span style="font-weight: bold;">“GDPR”</span>).\n
  
<div style="font-weight:bold;">ACCEPTANCE OF PRIVACY POLICY</div>
By accessing and using our Services, you signify acceptance to the terms of this Privacy Policy. We reserve the right to revise this Privacy Policy at any time to reflect changes in law or our personal data collection and use practices. If changes are made to this Privacy Policy, we will notify you by posting on the Interface or by means of a notice on our Services and you will be required to accept any updated privacy policy before you are able to use the Services.\n
If you do not agree with or you are not comfortable with any aspect of this Privacy Policy, you should immediately discontinue access or use of our Interface and Services.\n
If you have any questions about this Privacy Policy or our privacy practices, please contact us at info@warpspeed.capital.\n
   
<div style="font-weight:bold;">PURPOSE OF THIS PRIVACY POLICY</div>
This Privacy Policy aims to give you information on how we collect and process your personal data through your use of the Interface and Services. Our Interface and Services are not intended for children and we do not knowingly collect data relating to children.\n
It is of great importance that you read this Privacy Policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your personal data. This Privacy Policy supplements other notices and privacy policies, if any, and is not intended to override them.\n    
   
<div style="font-weight:bold;">YOUR DUTY TO INFORM US OF CHANGES</div>
It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.\n
<div style="font-weight:bold;">THIRD-PARTY LINKS</div>
Our Interface and Services may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our Interface or Services, we encourage you to read the privacy policy of every website you visit.\n  
<div style="font-weight:bold;">THE DATA WE COLLECT ABOUT YOU</div>
Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).\n
We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:\n   
•	<span style="font-weight:bold;">Identity Data</span> includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth, age, nationality, photographs, gender, job title, Tax ID number, passport number, driver’s license details, national identity card details, photograph identification cards, and/or visa information.
•	<span style="font-weight:bold;">Contact Data</span> includes billing address, delivery address, email address and telephone numbers.
•	<span style="font-weight:bold;">Financial Data</span> includes bank account, payment card primary account number (PAN), transaction history, trading data, and/or tax identification.
•	<span style="font-weight:bold;">Transaction Data</span> includes details about payments to and from you and other details of Services you have purchased from us, such as the name of the recipient, your name, the amount, and/or timestamp.
•	<span style="font-weight:bold;">Technical Data</span> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our Interface and Services.
•	<span style="font-weight:bold;">Profile Data</span> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.
•	<span style="font-weight:bold;">Usage Data</span> includes information provided to our support team, public social networking posts, authentication data, security questions, user ID, click-stream data and other data collected via cookies and similar technologies.
•	<span style="font-weight:bold;">Marketing and Communications Data</span> includes your preferences in receiving marketing from us and our third parties and your communication preferences.\n

We also collect, use and share <span style="font-weight:bold;">Aggregated Data</span> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.
   
We do not collect any <span style="font-weight:bold;">Special Categories of Personal Data</span> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data).
  
<div style="font-weight:bold;">IF YOU FAIL TO PROVIDE PERSONAL DATA</div>
Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.\n
<div style="font-weight:bold;">HOW IS YOUR PERSONAL DATA COLLECTED?</div>
We use different methods to collect data from and about you including through:
   
<span style="font-weight: bold;">Direct interactions</span>. You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:
  •	apply for our Services;
  •	create an account on our Interface or Services;
  •	subscribe to our Services or publications;
  •	request marketing material or content to be sent to you;
  •	enter a competition, promotion or survey; or
  •	give us feedback or contact us.\n
<span style="font-weight: bold;">Automated technologies/interactions and third party sources</span>. We may automatically collect your personal information as you interact with our Interface (such as browsing actions, patterns, hardware model, device ID, operating system version, web-browser software and your Internet Protocol address/MAC address/device identifier). We may also receive your personal data from various third parties and public sources as required or permitted by applicable law (such as public databases, credit bureaus, ID verification partners, resellers and channel partners, joint marketing partners, and social media platforms).\n
<div style="font-weight: bold;">PURPOSES FOR WHICH WE USE YOUR PERSONAL DATA</div>
We will only use your personal data within the limits allowed by law. Most commonly, with reference to the types of legal basis specifically outlined in the GDPR, we will use your personal data where:
   
  •	we need to perform the contract we are about to enter into or have entered into with you;
  •	it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; or
  •	we need to comply with a legal obligation.

Please see the below Glossary to find out more about the types of lawful basis that we will rely on to process your personal data.

<div style="font-weight: bold;">MARKETING</div>
We may use your personal data to form a view on what we think you may want or need, or what may be of interest to you with respect to the Interface or the provision of the Services. Based on your communication preferences, we may send you marketing communications to inform you about our events or our partner events; to deliver targeted marketing and advertising; and to provide you with promotional offers based on your communication preferences. 
   
<div style="font-weight: bold;">INFORMATION SHARED WITH THIRD PARTIES</div>
From time to time we may request your permission to allow us to share your personal data with third parties. We will only share your personal data with third parties who have a legitimate purpose for accessing it, particularly: 
•	We share your personal data with third party identity verification services in order to perform obligations under AML Laws, Anti-Bribery Laws, Sanctions Laws, CTF Laws, and other Applicable Laws ;
•	We share your personal data with service providers who provide consultancy, banking, legal, insurance and accounting services under contract and who help with parts of our business operations;
•	We share your personal data with financial institutions with which we partner to process payments you have authorised;
•	We may share your personal data with companies or other entities that we plan to merge with or be acquired by. Should such a combination occur, we will require that the new combined entity follow this Privacy Policy with respect to your personal data. You will receive prior notice of any change in applicable policies; and
•	We share your personal data with law enforcement, officials, or other third parties when we are compelled to do so by a subpoena, court order, or similar legal procedure, or when we believe in good faith that the disclosure of personal data is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate violations of our user agreement or any other applicable policies.
  
We require all third parties to respect the security of your personal data and to treat it in accordance with the Privacy Policy and applicable laws. 
   
<div style="font-weight: bold;">OPTING OUT</div>
You may opt out of having your personal data shared with third parties, or allowing us to use your personal data for any purpose that is incompatible with the purposes for which we originally collected it or subsequently obtained your authorisation. If you choose to do so, certain features of our Interface or Services may not be available to you.\n
<div style="font-weight: bold;">COOKIES</div>
You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our Cookie Policy.
   
<div style="font-weight: bold;">CHANGE OF PURPOSE</div>
We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
   
If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
   
Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
   
<div style="font-weight: bold;">INTERNATIONAL TRANSFERS</div>
Your personal data may be transferred, stored, and processed in any country in which we operate. 

<div style="font-weight: bold;">Data Subjects of the European Economic Area (“EEA”) Only:</div>
Where we transfer your personal data outside of the EEA, this is done either on the basis that it is necessary for the performance of the contract between you and Warpspeed, or that the transfer is subject to the European Commission’s model contracts for the transfer of personal data to third countries (i.e., the standard contractual clauses), pursuant to Decision 2004/915/EC and Decision 2010/87/EU as appropriate or, in the event that the transfer is to a US entity, we may transfer personal data if the transferee is part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US.
  
<div style="font-weight: bold;">DATA SECURITYM</div>
We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
   
We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
   
<div style="font-weight: bold;">DATA RETENTION</div>
We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
   
To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.\n
•	Contact Information such as your name, email address and telephone number for marketing purposes is retained on an ongoing basis until you unsubscribe.
•	Content that you post on our Interface such as support desk comments, photographs, videos, blog posts, and other content may be kept indefinitely after you close your account for audit and crime prevention purposes.
  
<div style="font-weight: bold;">YOUR LEGAL RIGHTS</div>
Under certain circumstances, you have rights under data protection laws in relation to your personal data, which include the right to:\n
•	<span style="font-weight: bold;">request access</span> to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data that we hold about you and to check that we are lawfully processing it;
•	<span style="font-weight: bold;">request correction</span> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us;
•	<span style="font-weight: bold;">request erasure</span> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons (for example, for complying with the requirements of retaining certain personal data for a specific period of time under the Applicable Laws) which will be notified to you, if applicable, at the time of your request;
•	<span style="font-weight: bold;">object to processing</span> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms;
•	<span style="font-weight: bold;">request restriction of processing</span> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
  
  -	If you want us to establish the data’s accuracy.
  -	Where our use of the data is unlawful but you do not want us to erase it.
  -	Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
  -	You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it;
  
•	<span style="font-weight: bold;">request the transfer</span> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you;
•	<span style="font-weight: bold;">withdraw consent</span> at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain Services to you. We will advise you if this is the case at the time you withdraw your consent.
  
Your rights in relation to your personal data are not absolute. Access may be denied when:
•	denial of access is required or authorised by law;
•	granting access would have a negative impact on another's privacy;
•	to protect our rights and property; and
•	where the request is frivolous or vexatious.\n
If you wish to exercise any of the rights set out above, please contact us. Note that we may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). We may also contact you to ask you for further information in relation to your request to speed up our response. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
We try to respond to all legitimate requests within 30 days. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. 
  
<div style="font-weight: bold;">GLOSSARY</div>
<div style="font-weight: bold;">LAWFUL BASIS</div>
<span style="font-weight: bold;">Legitimate Interest</span> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
   
<span style="font-weight: bold;">Performance of Contract</span> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.

<span style="font-weight: bold;">Comply with a legal obligation</span> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.
   
<div style="font-weight: bold;">CONTACT DETAILS</div>
If you have any questions about this Privacy Policy or our privacy practices, please contact us at: info@warpspeed.capital.
  
You have the right to make a complaint at any time to the Information and Data Protection Tribunal (“Tribunal”). We would, however, appreciate the chance to deal with your matter internally before approaching the Tribunal.  
`
}